import Enum from "./Enum";

const ServiceStatuses = new Enum({
    0 : 'Requested',
    1 : 'Accepted',
    2 : 'Scheduled',
    3 : 'In Progress',
    4 : 'Completed',
    5 : 'Failed'
});

export default ServiceStatuses;