import Model from './Model.js'

import Locations from './Locations';
import UserModel from './UserModel.js';

class AccountModel extends Model {
    constructor(values, config = {}){
        super(config);

        //set props
        this.prop('id',                 Number);

        this.prop('name',               String);

        this.prop('number',             String);

        this.prop('location',           Locations);

        this.prop('address',            String);

        this.prop('primary-contact',    String);

        this.prop('relation-number',    String)

        this.prop('manager',            UserModel);

        this.prop('users',              [UserModel]);

        this.prop('related-accounts',   [AccountModel]);



        //populate instance
        this.populate(values);
    }
    static name = "AccountModel";

}


export default AccountModel;
