import Model from './Model.js'

import ServiceModel from './ServiceModel.js';


class MaintenanceModel extends Model {
    constructor(values, config = {}){
        super(config);

        //set props
        this.prop('id',                     Number);

        this.prop('work-order-number',      String);

        this.prop('invoice-date',           Date);

        this.prop('sbs-breakdown-number',   String);

        this.prop('customer-po-number',     String);

        this.prop('amount',                 String);

        this.prop('service',                ServiceModel);

        //populate instance
        this.populate(values, config);
    }
    static name = "MaintenanceModel";

}


export default MaintenanceModel;
