<template>
    <td :data-title="columnTitle" :class="settings ? settings.class: ''">
        <span v-html="output(record, 0, settings, column)"></span>
    </td>
</template>

<script>
import FormatDataMixin from '../../mixins/FormatData';

    export default {
        components: {
        },
        props: [
            'record',
            'column', 
            'settings',
        ],
        data: function(){
            return {
            }
        },
        computed: {
            columnTitle: function(){
                return this.settings && Object.hasOwnProperty.call(this.settings, 'title') ? this.settings.title : this.column;
            },
        },
        setup(){
            const {output} = FormatDataMixin();
            return {output};
        }
    }
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";
td.data-error{

    > span {
        color: $red;
        position: relative;
        cursor: pointer;

        > span {
            overflow: hidden;
            width: 0;
            display: block;
            position: absolute;
            opacity: 0;
            transition: $transition-standard;
            box-shadow: $shadow-standard;
            border-radius: 5px;
            z-index: 110;

                > span {
                    display: block;
                    padding: $space-narrow $space-standard;
                    background: $white;
                    color: $font-color;
                    width: 300px;                
            }
        }
        &:hover {
            > span {
                width: 300px;
                opacity: 1;
            }
        }
    }
}
</style>