import Enum from "./Enum";

const Locations = new Enum({
    'ATLN': 'Atlanta North', 
    'ATLS': 'Atlanta South', 
    'CHAR': 'Charlotte', 
    'CINC': 'Cincinnati', 
    'CLEV': 'Cleveland', 
    'COLS': 'Columbus', 
    'DAYT': "Dayton", 
    'INDY': 'Indianpolis', 
    'LOUI': 'Louisville', 
    'MARY': "Marysville", 
    'NASH': "Nashville", 
    'TALL': "Talladega", 
    'TAMP': "Tampa"
});

export default Locations;