import Model from './Model.js'

import EquipmentModel from './EquipmentModel.js';
import AccountModel from './AccountModel.js';
import Issues from './Issues.js';
import UserModel from './UserModel.js';

import ServiceStatuses from './ServiceStatuses.js';

class ServiceModel extends Model {
    constructor(values, config = {}){
        super(config);

        //set props
        this.prop('id',             Number);

        this.prop('work-order-number',String);

        this.prop('account',        AccountModel);

        this.prop('requestor',      UserModel);

        this.prop('equipment',      EquipmentModel)
        
        this.prop('street',         String);
        
        this.prop('city',           String);
        
        this.prop('state',          String);
        
        this.prop('zip',            Number);

        this.prop('status',         ServiceStatuses);

        this.prop('issues',         [Issues]);

        //populate instance
        this.populate(values, config);


    }
    static name = "ServiceModel";
}


export default ServiceModel;
