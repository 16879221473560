import Enum from "./Enum";

const Issues = new Enum({
    I   : 'Inspection (PM/DOT)',
    B   : 'Brakes',
    S   : 'Suspension',
    T   : 'Tires',
    WE  : 'Wheel-end(s)',
    EL  : 'Electrical & Lighting',
    GPS : 'GPS / Telematics',
    C   : 'Cargo Handling',
    CS  : 'Coupling Systems',
    RC  : 'Reefer Components',
    D   : 'Door(s)',
    BC  : 'Body Components',
    FLG : 'Frame & Landing Gear',
    O   : 'Other',
});

export default Issues;