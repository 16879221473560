<template>
    <table class="headless-table">
        <tbody>
            <template v-for="(settings, column) in columns" :key="column">
                <tr>
                    <td>{{settings.title}}</td>
                    <table-cell :record="record" :column="column" :settings="settings" :level="0"></table-cell>
                </tr>
            </template>
        </tbody>
    </table>
</template>

<script>
import TableCell from './TableCell.vue';

    export default {
        props: [
            'columns',
            'record',
        ],
        components: {
            TableCell
        },
    }
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";

</style>